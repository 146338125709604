import React from "react";
import "./acne.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Acne = () => {
  return (
    <div>
      <Navbar />
      Acne
      <Footer />
    </div>
  );
};

export default Acne;
