import React from "react";

const Instagram = ({ fill }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375 9.9751C12.375 10.5684 12.1991 11.1485 11.8694 11.6418C11.5398 12.1352 11.0712 12.5197 10.5231 12.7467C9.97487 12.9738 9.37167 13.0332 8.78973 12.9175C8.20779 12.8017 7.67324 12.516 7.25368 12.0964C6.83412 11.6769 6.5484 11.1423 6.43264 10.5604C6.31689 9.97843 6.3763 9.37523 6.60336 8.82705C6.83042 8.27887 7.21494 7.81033 7.70829 7.48069C8.20164 7.15104 8.78166 6.9751 9.375 6.9751C10.1699 6.97757 10.9315 7.29443 11.4936 7.85651C12.0557 8.41858 12.3725 9.18021 12.375 9.9751ZM18.75 5.8501V14.1001C18.75 15.4925 18.1969 16.8278 17.2123 17.8124C16.2277 18.797 14.8924 19.3501 13.5 19.3501H5.25C3.85761 19.3501 2.52226 18.797 1.53769 17.8124C0.553123 16.8278 0 15.4925 0 14.1001V5.8501C0 4.45771 0.553123 3.12235 1.53769 2.13779C2.52226 1.15322 3.85761 0.600098 5.25 0.600098H13.5C14.8924 0.600098 16.2277 1.15322 17.2123 2.13779C18.1969 3.12235 18.75 4.45771 18.75 5.8501ZM13.875 9.9751C13.875 9.08508 13.6111 8.21505 13.1166 7.47503C12.6221 6.73501 11.9193 6.15823 11.0971 5.81764C10.2748 5.47705 9.37001 5.38793 8.49709 5.56156C7.62418 5.7352 6.82236 6.16378 6.19302 6.79312C5.56368 7.42245 5.1351 8.22428 4.96147 9.09719C4.78783 9.97011 4.87695 10.8749 5.21754 11.6972C5.55814 12.5194 6.13491 13.2222 6.87493 13.7167C7.61495 14.2112 8.48498 14.4751 9.375 14.4751C10.5685 14.4751 11.7131 14.001 12.557 13.1571C13.4009 12.3132 13.875 11.1686 13.875 9.9751ZM15.375 5.1001C15.375 4.87759 15.309 4.66009 15.1854 4.47508C15.0618 4.29008 14.8861 4.14588 14.6805 4.06073C14.475 3.97558 14.2488 3.95331 14.0305 3.99671C13.8123 4.04012 13.6118 4.14727 13.4545 4.3046C13.2972 4.46194 13.19 4.66239 13.1466 4.88062C13.1032 5.09885 13.1255 5.32505 13.2106 5.53062C13.2958 5.73618 13.44 5.91188 13.625 6.0355C13.81 6.15912 14.0275 6.2251 14.25 6.2251C14.5484 6.2251 14.8345 6.10657 15.0455 5.89559C15.2565 5.68461 15.375 5.39847 15.375 5.1001Z"
        fill={fill}
      />
    </svg>
  );
};

export default Instagram;
