import React from "react";
import AppWrapper from "./routes";
function App() {
  return (
    <div className="App">
      <AppWrapper />
    </div>
  );
}

export default App;
