import React from "react";

const LocationIcon = ({ fill }) => {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0017 12C10.6892 12 11.278 11.7648 11.768 11.2944C12.258 10.824 12.5025 10.2592 12.5017 9.6C12.5017 8.94 12.2567 8.3748 11.7667 7.9044C11.2767 7.434 10.6884 7.1992 10.0017 7.2C9.31421 7.2 8.72546 7.4352 8.23546 7.9056C7.74546 8.376 7.50088 8.9408 7.50171 9.6C7.50171 10.26 7.74671 10.8252 8.23671 11.2956C8.72671 11.766 9.31504 12.0008 10.0017 12ZM10.0017 24C6.64754 21.26 4.14254 18.7152 2.48671 16.3656C0.830876 14.016 0.00254232 11.8408 0.00170898 9.84C0.00170898 6.84 1.00713 4.45 3.01796 2.67C5.02879 0.89 7.35671 0 10.0017 0C12.6475 0 14.9759 0.89 16.9867 2.67C18.9975 4.45 20.0025 6.84 20.0017 9.84C20.0017 11.84 19.1734 14.0152 17.5167 16.3656C15.86 18.716 13.355 21.2608 10.0017 24Z"
        fill={fill}
      />
    </svg>
  );
};

export default LocationIcon;
